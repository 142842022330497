<template>
	<div class="pui-form">
		<mrescondicion-modals
			:modelName="modelName"
		></mrescondicion-modals>
		<pui-form-header
			v-if="modelLoaded && false"
			:showHeader="!isCreatingElement">
			<mrescondicion-form-header
				:modelPk="modelPk"
			></mrescondicion-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('mrescondicion.fieldset1')">
						<v-row dense >
							<!-- IDCONCOD -->
							<v-col cols="4" md="2" v-if="!isCreatingElement">
								<pui-number-field
									:id="`idconcod-mrescondicion`"
									v-model="model.idconcod"
									:label="$t('mrescondicion.idconcod')"
									disabled=
									required
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-col>
							<!-- CODCONRESEDI -->
							<v-col cols="6" md="2">
								<pui-text-field
									:id="`codconresedi-mrescondicion`"
									v-model="model.codconresedi"
									:label="$t('mrescondicion.codconresedi')"
									disabled
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-col>
							<!-- FECINIVIGENCIA -->
							<v-col cols="12" md="3" lg="2">
								<pui-date-field
									:id="`fecinivigencia-mrescondicion`"
									v-model="model.fecinivigencia"
									:label="$t('mrescondicion.fecinivigencia')"
									:disabled="formDisabled"
									toplabel
									required
									:max="model.fecfinvigencia"
								></pui-date-field>
							</v-col>
							<!-- FECFINVIGENCIA -->
							<v-col cols="12" md="3" lg="2">
								<pui-date-field
									:id="`fecfinvigencia-mrescondicion`"
									v-model="model.fecfinvigencia"
									:label="$t('mrescondicion.fecfinvigencia')"
									:disabled="formDisabled"
									toplabel
									:min="model.fecinivigencia"
								></pui-date-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="10">
								<pui-text-area
									:id="`name-mrescondicion`"
									v-model="model.name"
									:label="$t('mrescondicion.name')"
									:disabled="formDisabled"
									maxlength="350"
									toplabel
									required
									rows="2"
								></pui-text-area>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- IDPUERTOLOCAL -->
							<v-col cols="6" lg="3">
								<pui-select
									:id="`idpuertolocal-mrescondicion`"
									attach="idpuertolocal"
									:label="$t('mrescondicion.idpuertolocal')"
									toplabel
									clearable
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmpuertolocal"
									:itemsToSelect="[{ id: model.idpuertolocal }]"
									:modelFormMapping="{ id: 'idpuertolocal' }"
									itemValue="id"
									itemText="descripcion"
									:order="{ descripcion: 'asc' }"
									:required="almenounoobligatorio"
								></pui-select>
							</v-col>
							<!-- IDAREAOPERA -->
							<v-col cols="12" lg="7">
								<pui-select
									:id="`idareaopera-mrescondicion`"
									attach="idareaopera"
									:label="$t('mrescondicion.idareaopera')"
									toplabel
									clearable
									:disabled="formDisabled"
									v-model="model"
									modelName="vlupmareaoperacion"
									:itemsToSelect="[{ idareaopera: model.idareaopera }]"
									:modelFormMapping="{ idareaopera: 'idareaopera' }"
									itemValue="idareaopera"
									:itemText="item => `${item.codareaopera} - ${item.codareaoperaedi} - ${item.name}`"
									:order="{ name: 'asc' }"
									:filterId="`idpuertolocal-mrescondicion`"
									:filterMap="{ idpuertolocal: model.idpuertolocal }"
									:required="almenounoobligatorio"
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- IDTIPOMAN -->
							<v-col cols="12" lg="5">
								<pui-select
										:id="`idtipoman-mrescondicion`"
										attach="idtipoman"
										:label="$t('mrescondicion.idtipoman')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmtipomanipulacion"
										:itemsToSelect="[{ id: model.idtipoman }]"
										:modelFormMapping="{ id: 'idtipoman' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
										:required="almenounoobligatorio"
									></pui-select>
							</v-col>
							<!-- IDGRUPPRI -->
							<v-col cols="12" lg="4">
								<pui-select
									:id="`idgruppri-mrescondicion`"
									attach="idgruppri"
									:label="$t('mrescondicion.idgruppri')"
									toplabel
									clearable
									:disabled="formDisabled"
									:required="model.prioridad!=null"
									v-model="model"
									modelName="vlupmgrupoprioridad"
									:itemsToSelect="[{ idgruppri: model.idgruppri }]"
									:modelFormMapping="{ idgruppri: 'idgruppri' }"
									itemValue="idgruppri"
									:itemText="item => `${item.name}`"
									:order="{ name: 'asc' }"
								></pui-select>
							</v-col>
							<!-- PRIORIDAD -->
							<v-col cols="12" lg="1">
								<pui-number-field
									:id="`prioridad-mrescondicion`"
									v-model="model.prioridad"
									:label="$t('mrescondicion.prioridad')"
									:disabled="formDisabled"
									toplabel
									:required="model.idgruppri!=null"
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-col>
						</v-row>
						<v-row dense>
							<!-- IDRESCATEGORIA -->
							<v-col cols="12" lg="6">
								<pui-select
										:id="`idrescategoria-mrescondicion`"
										attach="idrescategoria"
										:label="$t('mrescondicion.idrescategoria')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="vlupmrescategoriacondicion"
										:itemsToSelect="[{ id: model.idrescategoria }]"
										:modelFormMapping="{ id: 'idrescategoria' }"
										itemValue="id"
										itemText="name"
										:order="{ name: 'asc' }"
									></pui-select>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('mrescondicion.fieldset2')">
						<v-row dense>
							<v-col cols="12" md="10">
								<pui-multi-select
									id="multiSelectGrupoMMPP"
									itemValue="grmpcodi"
									itemText="grmpdesc"
									v-model="model.gruposmmpp"
									:items="allGruposmmpp(model)"
									:itemsToSelect="model.gruposmmpp"
									:disabled="formDisabled"
									:key="refreshSelect"
								></pui-multi-select>
							</v-col>
						</v-row>
					</pui-field-set>
				<!-- MINI AUDIT -->
				<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="validateAndsaveAndNew"
					:saveAndUpdate="validateAndsaveAndUpdate"
					:save="validateAndsave"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mrescondicionActions from './MrescondicionActions';
import mrescondicionModals from './MrescondicionModals.vue';

export default {
	name: 'mrescondicion-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mrescondicion-modals': mrescondicionModals
	},
	data() {
		return {
			modelName: 'mrescondicion',
			actions: mrescondicionActions.formActions,
			refreshSelect: 0,
			almenounoobligatorio:false
		};
	},
	methods: {
		afterSave(data) {
			if(data.resultadosincronizacion) {
				if(data.resultadosincronizacion.startsWith('0')){
					this.$puiNotify.success(data.resultadosincronizacion.substring(1));
				}else{
					this.$puiNotify.error(data.resultadosincronizacion.substring(1));
				}
			}
		},
		allGruposmmpp(model) {
			if (!this.$appUtils.isEmpty(model.allgruposmmpp)) return model.allgruposmmpp;

			const params = {};

			const url = this.$store.getters.getModelByName(this.modelName).url.getGruposMmpp;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('mrescondicion.getGruposMmpp.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					// Valid response, do code and close modal
					model.allgruposmmpp = response.data.allgruposmmpp;
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		},
		validateAndsaveAndNew() {
			if(this.validateForm()) this.saveAndNew();
		},
		validateAndsaveAndUpdate() {
			if(this.validateForm()) this.saveAndUpdate();
		},
		validateAndsave() {
			if(this.validateForm()) this.save();
		},
		validateForm() {

			var enError = this.$appUtils.isEmpty(this.model.idpuertolocal) && this.$appUtils.isEmpty(this.model.idareaopera)
					&& this.$appUtils.isEmpty(this.model.idtipoman) && this.$appUtils.isEmpty(this.model.gruposmmpp)
			if(enError){
				this.$puiNotify.error(this.$t('mrescondicion.obligatoriouno'));
				return false;
			}

			return true;
		}
	},
	computed: {
	},
	created() {
	}
}
</script>
